import React from 'react';
import styles from './Logo.module.css';

function Logo({ className }) {
  return (
    <svg x="0" y="0" viewBox="187 70 200 120" className={className}>
      <g>
        <path
          fill="none"
          stroke="#AD8B19"
          strokeWidth="4"
          strokeMiterlimit="10"
          className={styles.leftHeart}
          d="M266.41,149.76c-9.57,6.51-17.16,10.42-17.2,10.4c-3.29-1.73-55.83-29.82-55.83-60.24
		c0-5.29,2.57-26.74,23.49-26.74c19.49,0,30.88,22.14,32.34,25.16c0,0,11.42-25.16,32.34-25.16c13.78,0,19.6,9.31,21.96,17.17"
        />

        <path
          fill="none"
          stroke="#AD8B19"
          strokeWidth="4"
          strokeMiterlimit="10"
          className={styles.rightHeart}
          d="M326.79,186.28c-3.29-1.73-55.83-29.82-55.83-60.24c0-5.29,2.57-26.74,23.49-26.74
		c19.49,0,30.88,22.14,32.34,25.16c0,0,11.42-25.16,32.34-25.16s23.49,21.46,23.49,26.74
		C382.62,157.45,326.89,186.34,326.79,186.28z"
        />
      </g>
    </svg>
  );
}

export default Logo;
